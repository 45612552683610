<template>
  <CRow>
    <CCol col="12">
      <CModal :show.sync="showModalCRUD" :centered="true" :title="$t(current_crud.title)" size="lg">
        <template v-if="showModalCRUD">
          <ModalCRUD :options="current_crud" @modalUpdated="onModalUpdated" />
        </template>
      </CModal>

      <CCard no-header :accentColor="isFormDirty ? (isFormValid ? 'success' : 'danger') : ''">
        <CCardBody>
          <h3>{{ form.id ? $t('Edit common area') + ` id: ${form.id}` : $t('Create common area') }}</h3>
          <CForm autocomplete="off" @keydown="clearErrors($event && $event.target ? $event.target.name : 'test')">
            <ACard>
              <AInput :horizontal="{ input: 'col-sm-8 col-lg-4' }" name="name" label="Zone name" v-model="form.name" :isValid="isValid('name')" :errors="getErrors('name')" />
              <AMultiSelect :horizontal="{ input: 'col-sm-8 col-lg-4' }" name="common_area_status_id" label="Status" v-model="form.common_area_status_id" :options="lists.common_area_statuses" :isValid="isValid('common_area_status_id')" :errors="getErrors('common_area_status_id')" />
              <AMultiSelectMaster :horizontal="{ input: 'col-sm-8 col-lg-4' }" name="common_area_group_id" label="Zone group" v-model="form.common_area_group_id" :options="lists.common_area_groups" @addElement="onAddElement('common_area_group')" />
              <AInput type="number" :horizontal="{ input: 'col-sm-4 col-lg-2' }" name="value" label="Rate" v-model="form.value" :isValid="isValid('value')" :errors="getErrors('value')" />
            </ACard>

            <ACard title="Controls">
              <AMultiSelect :horizontal="{ input: 'col-sm-8 col-lg-4' }" name="control_type" label="Control by time" v-model="form.control_type" :options="lists.control_types" :isValid="isValid('control_type')" :errors="getErrors('control_type')" />
              <AMultiSelect :horizontal="{ input: 'col-sm-8 col-lg-4' }" name="control_overdue" label="Control by overdue" v-model="form.control_overdue" :options="lists.control_overdue" :isValid="isValid('control_overdue')" :errors="getErrors('control_overdue')" />
              <AMultiSelect :horizontal="{ input: 'col-sm-8 col-lg-4' }" name="control_days" label="Available range" v-model="form.control_days" :options="lists.control_days" :isValid="isValid('control_days')" :errors="getErrors('control_days')" />
              <AMultiSelect :horizontal="{ input: 'col-sm-8 col-lg-4' }" name="available" label="Available days" v-model="form.available" :options="lists.days" :multiple="true" :closeOnSelect="false" :config="{ select_all: true }" :isValid="isValid('available')" :errors="getErrors('available')" />
              <AMultiSelect :horizontal="{ input: 'col-sm-8 col-lg-4' }" name="shift_time" label="Shift time" v-model="form.shift_time" :options="lists.shifts" :isValid="isValid('shift_time')" :errors="getErrors('shift_time')" />

              <AInputCounter :horizontal="{ input: 'col-sm-2' }" name="concurrency" label="Concurrency" v-model="form.concurrency" :min="1" @change="value => (form.concurrency = value)" />
              <AInputCounter :horizontal="{ input: 'col-sm-2' }" name="max_reservation" label="Max reservation" v-model="form.max_reservation" :min="1" @change="value => (form.max_reservation = value)" />
            </ACard>

            <ACard title="Time schedule">
              <ACheckBox label="All day" placeholder="La reserva no tiene horario definido, se podrá realizar durante cualquier horario del día." v-model="form.all_day" />

              <template v-if="!this.form.all_day">
                <AInputDatetime :horizontal="{ input: 'col-sm-2' }" name="min_time" label="Min time" type="time" v-model="form.min_time" :use12-hour="true" :minute-step="15" format="hh:mm a" value-zone="America/Bogota" zone="America/Bogota" :isValid="isValid('min_time')" :errors="getErrors('min_time')" />
                <AInputDatetime :horizontal="{ input: 'col-sm-2' }" :disabled="!form.min_time" :min-datetime="form.min_time" name="max_time" label="Max time" type="time" v-model="form.max_time" :use12-hour="true" :minute-step="15" format="hh:mm a" value-zone="America/Bogota" zone="America/Bogota" :isValid="isValid('max_time')" :errors="getErrors('max_time')" @close="clearErrors('max_time')" />
              </template>
            </ACard>
          </CForm>
          <CCardFooter>
            <CButton class="mr-2" :color="form.id ? 'info' : 'primary'" adisabled="!isFormValid" @click="submit">{{ $t(form.id ? 'Save' : 'Create') }}</CButton>
            <CButton color="secondary" @click="goBack">{{ $t('Back') }}</CButton>
          </CCardFooter>
        </CCardBody>
      </CCard>
    </CCol>

    <!-- // TODO: Debug view... create a global component -->
    <CCol v-if="DEBUG" col="12">
      <pre>{{ form }}</pre>
      <div class="summary text-red" v-if="$v.form.$error">
        Form has errors
        <pre>{{ showErrors }}</pre>
      </div>
    </CCol>
  </CRow>
</template>

<script>
import formMixin from '@/app/views/_mixins/form-mixin'

import { DEBUG } from '@/config/config'

export default {
  name: 'CommonAreaForm',
  mixins: [formMixin],

  data: () => {
    return {
      DEBUG: DEBUG, // TODO: Global or in Helper

      // DATA
      form: { id: 0, available: [] },

      // Extra
      lists: {},

      // Helpers
      alerts: [],
      validators: {},
      current_crud: {},
      is_principal: false,
      showModalCRUD: false,
      original_lists: {}
    }
  },
  created() {
    this.form.id = this.$route.params.id || 0
    this.getData()
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    getData() {
      const self = this
      self.$http
        .get('admin/common_areas' + (self.form.id ? `/${self.form.id}/edit` : '/create'), { _lists: 'common_area_groups,common_area_statuses,days,shifts,control_days,control_types,control_overdue,days' })
        .then(response => {
          self.parseData(response.data)
        })
        .catch(error => {
          console.error(error)
          self.showAlert(`There was an error.`, 'danger')
          //self.goBack() // TODO: Alert
          //self.$router.push({ path: 'login' })
        })
    },
    onAddElement(type) {
      this.current_crud = {
        url: `admin/${type}s`, // Pluralize
        name: type,
        type: `${type}s`, // Pluralize
        field: `${type}_id`,
        title: this._humanize(type),
        modal: true,
        fiels: ['name']
      }
      this.showModalCRUD = true
    },
    onModalUpdated(items) {
      this.lists[this.current_crud.type] = items
      // Force refresh list
      const value = this.form[this.current_crud.field]
      this.form[this.current_crud.field] = ''
      this.$nextTick(() => {
        this.form[this.current_crud.field] = value
      }, 100)
    },

    // Parse Extra and Related data
    parseData(data) {
      this.form = data.common_area ? data.common_area : { id: 0, available: [] }
      this.is_principal = !!this.form.is_principal
      if (!Array.isArray(data._lists)) this.lists = data._lists || {}

      this.parseView()

      this.validators = {
        main: data._validation || {}
        //extra: data._extra_validation || {}
      }

      if (_.isEmpty(this.$v.$form)) this.parseValidator(data._validation, data._messages, true)

      //if (this.form.id) this.forcedSteps()
    },

    // Parsing

    parseView() {
      const options = [
        { click: this.submit, class: 'mr-2 btn-' + (this.form.id ? 'info' : 'primary'), content: this.$t(this.form.id ? 'Save' : 'Create') }, //disabled: this.isFormValid },
        { click: this.goBack, class: 'btn-secondary', content: this.$t('Back') }
      ]
      this.$store.dispatch('setTopActions', { [(this.form.id ? 'Edit' : 'Create') + 'CommonArea']: options })
    },

    // SUBMIT

    submit() {
      //console.log(this.form)

      this.$v.form.$touch()
      if (this.$v.form.$error) return
      // TODO: On backend rules ?
      if (this.form.min_time && !this.form.max_time) return this.setErrors({ max_time: ['Debe seleccionar hora fin'] })
      if (this.form.max_time && !this.form.min_time) return this.setErrors({ max_time: ['Debe seleccionar hora inicio'] })
      if (this.form.max_time <= this.form.min_time) return this.setErrors({ max_time: ['Hora fin no puede ser menor o igual a Hora inicio'] })
      if (!this.form.all_day && !this.form.shift_time) return this.setErrors({ shift_time: ['Si la zona común tiene horario debe seleccionar la duración del turno'] })

      const self = this
      self.$http[self.form.id ? 'put' : 'post']('admin/common_areas', self.form) // TODO: on service ?
        .then(response => {
          self.$router.replace(`/admin/tools/common_areas/${response.data.common_area.id}/edit`).catch(() => {})
          self.showAlert(`Common area ${self.form.id ? 'saved' : 'created'}.`)
          self.parseData(response.data)
        })
        .catch(error => {
          // TODO: move to form helper ?
          //if (error && error.status === 422) {
          if (error.response && error.response.status === 422) {
            console.log('parseInputErrors')
            self.setErrors(error.response.data.errors || {})
          }
          if (error.response?.data?.message == 'The given data was invalid.') {
            for (const key in error.response.data.errors) {
              if (error.response.data.errors[key]) {
                self.message += error.response.data.errors[key][0] + '  '
              }
            }
          } else {
            console.error(error)
            self.showAlert(`There was an error ${self.form.id ? 'saving' : 'creating'} the common area.`, 'danger')
            //self.goBack() // TODO: login ?
          }
        })
    }
  }
}
</script>
